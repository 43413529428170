<template>
	<div class="minimal">
		<ColorButton
			v-for="color in MAIN_WEBSITE_COLORS"
			:key="color"
			v-qa="`builder-colorpicker-websitecolor-btn-${color}`"
			:color="transformColorToVariable(color)"
			:selected-color="selectedColor"
			@click="$emit('select-color', $event)"
		/>
		<ModeButton
			data-qa="builder-colorpicker-toggle-picker"
			:icon-name="CUSTOM_COLOR_ICON"
			:selected-color="selectedColor"
			:mode="COLOR_PICKER_MODE_CUSTOM_COLOR"
			@click="$emit('change-mode', $event)"
		/>
		<ModeButton
			data-qa="builder-colorpicker-toggle-full"
			icon-name="chevron-down-small"
			:selected-color="selectedColor"
			:mode="COLOR_PICKER_MODE_FULL"
			@click="$emit('change-mode', $event)"
		/>
	</div>
</template>

<script>
import {
	MAIN_WEBSITE_COLORS,
	COLOR_PICKER_MODE_CUSTOM_COLOR,
	COLOR_PICKER_MODE_FULL,
	CUSTOM_COLOR_ICON,
} from '@/components/global/zyro-color-picker/constants';
import ColorButton from '@/components/global/zyro-color-picker/misc/ColorButton.vue';
import ModeButton from '@/components/global/zyro-color-picker/misc/ModeButton.vue';
import { transformColorToVariable } from '@/utils/colorVariableModifiers';

export default {
	components: {
		ModeButton,
		ColorButton,
	},
	props: {
		selectedColor: {
			type: String,
			required: true,
		},
	},
	setup() {
		return {
			transformColorToVariable,
			MAIN_WEBSITE_COLORS,
			COLOR_PICKER_MODE_CUSTOM_COLOR,
			COLOR_PICKER_MODE_FULL,
			CUSTOM_COLOR_ICON,
		};
	},
};
</script>

<style lang="scss" scoped>
.minimal {
	position: absolute;
	display: flex;
	overflow: hidden;
	background-color: $light;
	border-radius: $border-radius-small;
	box-shadow: $box-shadow;
}
</style>
