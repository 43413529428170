<template>
	<div class="container">
		<ZyroButton
			class="mode-button"
			:class="{
				'mode-button--selected': isSelected && showSelected,
				'mode-button--border-radius': hasBorderRadius,
			}"
			:style="computedStyles"
			@click="$emit('click', mode)"
		>
			<ZyroSvg
				:name="iconName"
				class="mode-button__icon"
				:class="`mode-button__icon--${iconName}`"
			/>
		</ZyroButton>
		<slot />
	</div>
</template>

<script>

import {
	CUSTOM_COLOR_ICON,
	ALL_WEBSITE_COLORS,
	MAIN_WEBSITE_COLORS,
} from '@/components/global/zyro-color-picker/constants';
import {
	isCustomColor,
	stripColorFromVariable,
} from '@/utils/colorVariableModifiers';

export default {
	props: {
		iconName: {
			type: String,
			required: true,
		},
		mode: {
			type: String,
			required: true,
		},
		selectedColor: {
			type: String,
			default: null,
		},
		showSelected: {
			type: Boolean,
			default: true,
		},
		hasBorderRadius: {
			type: Boolean,
			default: true,
		},
	},
	computed: {
		computedStyles() {
			return { '--droplet-color': this.dropletColor };
		},
		dropletColor() {
			if (!this.selectedColor) {
				return 'transparent';
			}

			return isCustomColor(this.selectedColor) ? this.selectedColor : 'transparent';
		},
		isMainWebsiteColorSelected() {
			const strippedSelectedColor = stripColorFromVariable(this.selectedColor);

			return ALL_WEBSITE_COLORS.includes(strippedSelectedColor)
				&& !MAIN_WEBSITE_COLORS.includes(strippedSelectedColor);
		},
		isSelected() {
			if ((this.iconName === CUSTOM_COLOR_ICON && isCustomColor(this.selectedColor))
				|| (this.isMainWebsiteColorSelected && this.iconName !== CUSTOM_COLOR_ICON)) {
				return true;
			}

			return false;
		},
	},
};
</script>

<style lang="scss" scoped>
.container {
	position: relative;
}

.mode-button {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 0;

	&:hover,
	&:focus {
		background-color: $grey-200;
		border: none;
	}

	&--border-radius {
		border-radius: $border-radius-small;
	}

	&--selected::before {
		position: absolute;
		width: 24px;
		height: 24px;
		content: '';
		border: 1px solid $accent-two;
		border-radius: $border-radius-small;
	}

	&__icon {
		position: relative;

		&--droplet {
			color: var(--droplet-color);

			&:hover,
			&:focus {
				color: var(--droplet-color);
			}
		}
	}
}
</style>
